import React from "react";
import styled from '@emotion/styled'
import Layout from "../components/layout";
import translate from "../utils/translation";
import { MainTitle } from "../components/common/title";
import cloud from '../../static/images/cloud.svg';

const Wrapper = styled.section`
  height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  width: 100%;
  background: url(${cloud}) right center no-repeat, linear-gradient(to bottom, #F7CBCC, #FBF9F8);
  background-size: 90%;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  @media (max-width: 760px) {
    padding: 0 24px;
  }
`;

const Content = styled.p`
  width: 50%;
  font-size: 16px;
  line-height: 1.88;
  margin: 0 0 16px;

  @media (max-width: 760px) {
    width: 100%;
    letter-spacing: 0.8px;
  }
`;

const MailLink = styled.a`
  color: #f38f8f;
  font-size: 16px;
  line-height: 1.88;
`;

export default () => 
  <Layout activeTabIndex={4}>
    <Wrapper>
      <Container>
        <MainTitle>
          {translate("contactUs")}
        </MainTitle>
        <Content>
          {translate("contactMessage")}
        </Content>
        <MailLink href="mailto:hello@nobrainwriting.com" target="_blank">hello@nobrainwriting.com</MailLink>
      </Container>
    </Wrapper>
 </Layout>;